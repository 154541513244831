export const Experiences = [
  {
    title: 'Proground',
    name: 'Proground & SuperWalk App',
    date: '06/2022 - 09/2022',
    detail: [
      '• Led the development of two major React Native applications, swiftly bring them to market, while closely collaborating with UX/UI and backend teams to ensure seamless integration and optimize user experience',
      '• Actively engaged in interview processes to recruit and lead front end developers fostering a collaborative and high performing team environment',
      '• Experienced in working in AGILE-based development environment and participating in business requirement gathering sessions with clients to ensure efficient project execution and alignment with organizational goals',
      '• Developed and tested new features every week, updating iOS and Android applications. This resulted in over 10,000 installations from users.',
      '• Researched and integrated GarminConnect Apis into the application, providing substantial real-time data support for users',
    ],
  },
  {
    title: 'Forc Shopping',
    name: 'Forc Shopping App',
    date: '05/2021 - 07/2021',
    detail: [
      '• Create new features and developed UI using React Native for mobile applications, while leveraging React for the development of the admin web page',
      '• Implemented a search functionality by integrating Flask and React to efficiently retrieve a large amount of data for the search engine',
    ],
  },
]

export const Projects = [
  {
    title: 'Local Service Provider Connection Platform',
    short: 'My SQL, NestJs, NextJs, Prisma, Git',
    info:
      'Website that allows users to search for, rate, and hire local service providers to work on a variety of personal projects',
    git: 'https://github.com/orgs/web-architectures-project/repositories',
  },
  {
    title: 'Machine Learning Models of Gait Analysis',
    short: 'Android, Java, Python, Firebase, Colab ',
    info:
      'A Study on the Method of Differentiating Between Elderly Walking and Non-Senior Walking Using Machine Learning Models',
    site: 'http://koreascience.or.kr/article/JAKO202128837704022.page',
  },
  {
    title: 'User Experience Research - 4 Mobile apps design',
    short: 'Figma',
    info:
      'Conducted research and surverys to identify service issues, define solutions, and establish userflow, while also designing UI/UX',
    site: 'https://soyeonwork.webflow.io/',
  },
]
